.live__indicator__container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
.direction_indicator,
.live_indicator{
    display: flex;
    width: 30px;
    height: 30px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: rgba(240, 240, 240, 0.20);
}
.live_indicator{
    &::after{
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #727272;
        transition: 1s ease-in-out;
    }
    &.live{
        &::after{
            background: #CCF54E;
        }
    }
}
.direction_indicator{
    cursor: pointer;
}