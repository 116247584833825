.gs_report_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.gs_sat_gs_alignment{
    width: 100%;
    display: flex;
    flex-direction: row;
    span{
        width: 40%;
    }
}

.modal_alignment_dropdown{
    width: 60%;
    display: flex;
    flex-direction: row;
}