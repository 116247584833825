.coverage_report_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.coverage_header_section{
    display: flex;
    padding: 8pt var(--12, 12pt) 0pt var(--12, 12pt);
    align-items: center;
    gap: var(--12, 12pt);
    align-self: stretch;
}

.coverage_header_section_selection{
    display: flex;
    height: 34pt;
    padding: 8pt var(--12, 12pt);
    justify-content: center;
    align-items: center;
    gap: 10pt;
    flex: 1 0 0;
    border-radius: 4pt 4pt var(--none, 0pt) var(--none, 0pt);

    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 400;
        line-height: 24pt; /* 200% */
    }
}

.not_selectable{
    opacity: 0.3;
}

.selected_subsection{
    border-radius: 4pt;
    background: rgba(204, 245, 78, 0.10);
    color: #CCF54E;

    span{
        color: #CCF54E;
    }
}

.report_title_section{
    display: flex;
    width: 100%;
    padding: 8pt 4pt 8pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.date_title{
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    gap: 2pt;
}

.zomm_in_icon{
    width: var(--24, 24pt);
    height: var(--24, 24pt);    
}

.report_header_section{
    color: #CCF54E;
    font-family: Montserrat;
    font-size: 16pt;
    font-style: normal;
    font-weight: 400;
    line-height: 24pt; /* 150% */
}

.report_date_section{
    color: rgba(240, 240, 240, 0.50);
    font-family: Montserrat;
    font-size: 10pt;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ouput_section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8pt;
    align-self: stretch;
}

.output_header_block{
    display: flex;
    padding: 6pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #202318;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 14pt;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.overview_section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4pt var(--12, 12pt) var(--12, 12pt) var(--12, 12pt);
    gap: 8pt;
    align-self: stretch;

    span{
        color: #CCF54E;
        font-family: Montserrat;
        font-size: 14pt;
        font-style: normal;
        font-weight: 400;
        line-height: 24pt; /* 171.429% */
    }
}

.object_style{
    color: #FFF;
    font-family: Montserrat;
    font-size: 14pt;
    font-style: normal;
    font-weight: 400;
    line-height: 24pt; /* 171.429% */
}

.overview_setion_item span:nth-child(1) {
    color: rgba(255, 255, 255, 0.50);
    font-family: Montserrat;
    font-size: 12pt;    
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.overview_setion_item span:nth-child(2) {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.overview_detail_section{
    display: flex;
    padding: var(--12, 12pt);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--12, 12pt);
    align-self: stretch;    
    border-radius: 8pt;
    background: #000;
}

.overview_setion_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.overview_setion_item span:nth-child(1) {
    color: rgba(255, 255, 255, 0.50);
    font-family: Montserrat;
    font-size: 12pt;    
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.overview_setion_item span:nth-child(2) {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.coverage_path_section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8pt;
    align-self: stretch;
}

.coverage_path_header_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    span{
        color: #FFF;
        font-family: Montserrat;
        font-size: 14pt;
        font-style: normal;
        font-weight: 400;
        line-height: 24pt; /* 171.429% */
    }
}


.overview_section_header_block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    span{
        color: #FFF;
        font-family: Montserrat;
        font-size: 14pt;
        font-style: normal;
        font-weight: 400;
        line-height: 24pt; /* 171.429% */
    }
}

.right_side_overview_section{
    display: flex;
    align-items: center;
    gap: 4pt;
    span{
        color: rgba(240, 240, 240, 0.80);
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


.input_object_section{
    display: flex;
    height: 67pt;
    padding: var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8pt;
    background: #000;
}


.input_object_section span:nth-child(1) {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 14pt;
    font-style: normal;
    font-weight: 400;
    line-height: 16pt; /* 114.286% */
}

.input_object_section span:nth-child(2) {
    color: rgba(240, 240, 240, 0.70);
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 300;
    line-height: 16pt; /* 133.333% */
}

.input_sections{
    display: flex;
    width: 78pt;
    flex-direction: column;
    align-items: flex-start;
    gap: 4pt;
}

.verticle_devider{
    width: 0.5pt;
    height: 43pt;
    background: rgba(240, 240, 240, 0.50);
}