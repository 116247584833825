.FeatureMap .sidebarOpen {
  left: 620px;
}

.FeatureMap .mapboxgl-control-container {
  position: absolute !important;
  top: 10%;
  left: 80%;
}

.FeatureMap .mapboxgl-ctrl-group button+button {
  border-top: 0px solid #fff !important
}

.FeatureMap .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  display: flex !important;
}

.FeatureMap .mapbox-gl-draw_ctrl-draw-btn {
  background-color: transparent;
}

.FeatureMap .mapboxgl-ctrl-group.mapboxgl-ctrl {
  position: fixed !important;
  top: 60px !important;
  right: 130px !important;
  border: 1px solid rgba(240, 240, 240, 0.5);
  padding: 6px 4px 6px 4px;
  background: rgba(25, 25, 26, 1) !important;
  box-shadow: 0px 4px 10px 0px #0000004D;
  /* width: 106px; */
  height: 42px;
  border-radius: 4px;
  margin: 0 !important;

}

.FeatureMap .mapbox-gl-draw_polygon {
  background-image: url(../../MissionModelling/ImageSvgs/AOI.svg) !important;
  margin: 0px 5px 0px 0px;
  background-size: 16px;
  border-radius: 4px !important;
}

.FeatureMap .mapbox-gl-draw_trash {

  background-image: url(../../MissionModelling/ImageSvgs/Trash-icon_feature.svg) !important;

  /* margin: 0px 0px 0px 5px; */
  background-size: 16px;
}

.FeatureMap .mapbox-gl-draw_point {
  background-image: url('data:image/svg+xml;utf8,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 13.5714L9 17M13.5714 9H17M9 4.42857V1M4.42857 9H1M9 11.2857C8.37143 11.2857 7.83315 11.0617 7.38515 10.6137C6.93715 10.1657 6.71353 9.62781 6.71429 9C6.71429 8.37143 6.93829 7.83315 7.38629 7.38515C7.83429 6.93715 8.37219 6.71353 9 6.71429C9.62857 6.71429 10.1669 6.93829 10.6149 7.38629C11.0629 7.83429 11.2865 8.37219 11.2857 9C11.2857 9.62857 11.0617 10.1669 10.6137 10.6149C10.1657 11.0629 9.62781 11.2865 9 11.2857Z" stroke="%23F0F0F0" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/></svg>') !important;
  margin: 0px 5px 0px 0px;
  background-size: 16px;
  border-radius: 4px;
}

.bbFooter {
  position: absolute;
  right: 0;
  top: 100.2%;
  border-radius: 4px;
  background: #20563d;
  font-size: 12px;
  padding: 5px 15px 5px 15px;
}

.closeButton {
  width: 40px;
  height: 42px;
  position: fixed;
  top: 60px;
  right: 30px;
  background: rgba(25, 25, 26, 1);
  border: 1px solid rgba(240, 240, 240, 0.5);
  cursor: pointer;
  font-size: 35px;
  color: rgba(240, 240, 240, 1);
  border-radius: 4px;
  line-height: 36px;
  text-align: center;

  svg {
    position: fixed;
    top: 75px;
    right: 43px;
  }
}

.uploadButton {
  background-image: url(../../MissionModelling/ImageSvgs/upload_feature.svg) !important;
  background-repeat: no-repeat !important;
  width: 29px;
  height: 29px;
  position: fixed;
  top: 6px;
  right: 4.5px;
  background: transparent;
  background-position: center;
  /* Centers the image within the element */
  color: rgba(240, 240, 240, 1);
  background-size: 19px;
  z-index: 111;
  border-radius: 4px;
}

.uploadButton.active {
  background-color: #3D4524;
  /* Active color after click */
}

.listOfFeatures {
  width: 320px;
  /* Fixed width */
  height: 38px;
  /* Fixed height */
  padding: 4px 12px;
  position: absolute;
  top: 80px;
  left: 20px;
  gap: 0px;
  background: rgba(15, 15, 15, 0.69);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(240, 240, 240, 0.8);
  backdrop-filter: blur(250px);
  border-bottom: 0.5px solid;
  border-image: linear-gradient(90deg, rgba(240, 240, 240, 0.2) 3.36%, rgba(240, 240, 240, 0) 120.86%) 1;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 8px 0px 0px;
  border-bottom: 0.5px solid #F0F0F0;
  border-radius: 0px 8px;
  border: 0.5px solid rgba(240, 240, 240, 0.20);
  background: rgba(15, 15, 15, 0.69);
  box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
  backdrop-filter: blur(125px);
}

.exitButton {
  width: 40px;
  height: 42px;
  position: fixed;
  top: 60px;
  right: 30px;
  background: rgba(25, 25, 26, 1);
  border: 1px solid rgba(240, 240, 240, 0.5);
  cursor: pointer;
  font-size: 35px;
  color: rgba(240, 240, 240, 1);
  border-radius: 4px;
  line-height: 36px;
  text-align: center;
  padding: 12px 5px;
  backdrop-filter: blur(20px);
  box-shadow: 0px 4px 4px 0px #00000040;

}

.uploadButtonBig {
  width: 40px;
  height: 42px;
  position: fixed;
  top: 60px;
  right: 80px;
  background: rgba(25, 25, 26, 1);
  border: 1px solid rgba(240, 240, 240, 0.5);
  cursor: pointer;
  font-size: 35px;
  color: rgba(240, 240, 240, 1);
  border-radius: 4px;
  line-height: 36px;
  text-align: center;
  padding: 12px 5px;
  backdrop-filter: blur(20px);
  box-shadow: 0px 4px 4px 0px #00000040;
}


.exitButtonText {
  color: #F0F0F0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  position: fixed;
  top: 12px;
  right: 4px;
}

.exitButtonDisabled {
  color: rgba(240, 240, 240, 0.5);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  position: fixed;
  top: 12px;
  right: 4px;
}

.tickButton {
  width: 40px;
  height: 42px;
  position: fixed;
  top: 60px;
  right: 81px;
  background: rgba(25, 25, 26, 1);
  border: 1px solid rgba(240, 240, 240, 0.5);
  cursor: pointer;
  font-size: 35px;
  color: rgba(240, 240, 240, 1);
  border-radius: 4px;
  line-height: 36px;
  text-align: center;

  svg {
    position: fixed;
    top: 77px;
    right: 95px;
  }
}

.fileNameFont {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #F0F0F0;
}

/* General tooltip positioning */
.polygon-tooltip,
.trash-tooltip,
.point-tooltip {
  position: absolute;
  transform: translateX(-50%);
  color: white;
  font-size: 12px;
  top: 149%;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}



/* Show each tooltip on hover */
.mapbox-gl-draw_ctrl-draw-btn:hover .polygon-tooltip,
.mapbox-gl-draw_ctrl-draw-btn:hover .trash-tooltip,
.mapbox-gl-draw_ctrl-draw-btn:hover .point-tooltip {
  opacity: 1;
}

/* Individual styles for each tooltip */
.polygon-tooltip {
  background-color: rgba(9, 9, 9, 0.80);
  padding: 8px;
}

.trash-tooltip {
  background-color: rgba(9, 9, 9, 0.80);
  padding: 8px;
}

.point-tooltip {
  background-color: rgba(9, 9, 9, 0.80);
  padding: 8px;
}

