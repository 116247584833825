.console__container {
    display: flex;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    border-radius: 8px;
    justify-content: space-between;
    height: 100%;
    width: auto;
    overflow: hidden;
    background: rgba(0,0,0,0.5);
    /* cursor: pointer; */
}

.console__container__left__panel {
    display: flex;
    height: 100%;
    width: 50px;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(25px);
    padding: 12px 10px;
    border-right: 0.5px solid rgba(240, 240, 240, 0.20);
    transition: all 0.3s ease;
    overflow: hidden;
    z-index: 9;

    &.disabled{
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
        width: 50x;
    }

    &.active {
        background: rgba(255, 255, 255, 0.01);
        width: 350px;
        overflow: visible;

        .console__container__left__panel__container {
            .left__panel__top__container {
                .left__panel__header {
                    justify-content: space-between;

                    .header__title {
                        display: flex
                    }

                    .left__panel__colapse__btn {
                        transform: rotate(180deg);
                    }
                }

                ;

                .left__panel__body {
                    width: 100%;
                    overflow: visible;
                }
            }

            .left__panel_bottom__container {
                width: 100%;
                overflow: visible;
            }
        }
    };

    .console__container__left__panel__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        .left__panel__top__container {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            height: auto;

            .left__panel__header {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 30px;
                padding-bottom: 12px;
                border-bottom: 0.5px solid rgba(240, 240, 240, 0.08);

                .header__title {
                    display: none;
                    color: #CCF54E;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .left__panel__close__btn,
                .left__panel__pin__btn,
                .left__panel__colapse__btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: transparent;
                    padding: 0px;
                    width: 30px;
                    height: 30px;
                    transform: rotate(0deg);
                    transition: all 0.3s ease;

                    &:disabled{
                        pointer-events: none;
                        cursor: default;
                        width: 50x;
                        opacity: 0.6;
                    }
                }

                .left__panel__pin__btn.pinned {
                    border-radius: var(--4, 4px);
                    background: rgba(204, 245, 78, 0.10);
                    svg{
                        transform: rotate(-45deg);
                        stroke: #CCF54E;
                        stroke-width: 0.5px;
                        transition: all 0.3s ease;
                    }
                }
            };

            .left__panel__body {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 0;
                overflow: hidden;
                height: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                &::-webkit-scrollbar-thumb {
                    background: transparent;
                }

                .left__panel__body__communication__btn__container {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    .communication_btn {
                        display: flex;
                        padding: var(--8, 8px) 12px;
                        align-items: center;
                        border-radius: var(--4, 4px);
                        background: rgba(240, 240, 240, 0.05);
                        color: #F0F0F0;
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        opacity: 0.8;

                        &.active {
                            color: #CCF54E;
                            border: 0.5px solid rgba(204, 245, 78, 0.60);
                            background: rgba(204, 245, 78, 0.10);
                        }
                        &:disabled{
                            pointer-events: none;
                            cursor: default !important;
                            opacity: 0.6;
                            user-select: none;
                        }
                    }
                }

                .left__panel__body__form__section__container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    align-items: start;
                    justify-content: start;
                    font-size: 12px;

                    .left__panel__body__input__option__container {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        align-items: start;
                        justify-content: start;
                        width: 100%;

                        .input__label {
                            color: #AEAEAE;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        };

                        .input__box__container {
                            width: 100%;
                            height: auto;
                            display: flex;
                            flex-direction: column;
                            gap: 4px;

                            .input__box {
                                display: flex;
                                height: 32px;
                                padding: 8px 12px;
                                align-items: center;
                                flex: 1;
                                border-radius: 4px;
                                border: 0.5px solid #535353;
                                color: #F0F0F0;
                                background: transparent !important;
                                font-family: Montserrat;
                                font-size: 12px;
                                cursor: pointer;

                                &::placeholder {
                                    color: rgba(255, 255, 255, 0.65);
                                };

                                &:focus {
                                    outline: none;
                                    cursor: default;
                                }
                            };

                            .input__feedback {
                                color: #ff0000;
                                font-family: Montserrat;
                                font-size: 10px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                scale: 0;
                                transition: all 0.3s ease;
                            }

                            &.feedback {
                                .input__box {
                                    border: 0.5px solid #ff0000;
                                }

                                ;

                                .input__feedback {
                                    scale: 1;
                                }
                            }
                        }
                    };

                    .input__preview__container {
                        width: 100%;
                        height: auto;
                        max-height: 250px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0.5px solid rgba(240, 240, 240, 0.20) !important;
                        border-radius: 4px;
                        color: #F0F0F0;
                        font-size: 12px;
                    }
                    .input__preview__container_big {
                        width: 100%;
                        height: 377px;
                        max-height: 250px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 0.5px solid rgba(240, 240, 240, 0.20) !important;
                        border-radius: 4px;
                        color: #F0F0F0;
                        font-size: 12px;
                    }
                }

            }
        };

        .left__panel_bottom__container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 0;
            height: auto;
            overflow: hidden;

            .form__submit__section__container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: end;
                gap: 12px;
                /* height: 180px; */
                overflow: visible;
                .empty__select__gs__button{
                    display: flex;
                    height: 35px;
                    padding: var(--4, 4px);
                    justify-content: center;
                    align-items: center;
                    align-self: stretch;
                    border-radius: 2px;
                    border: 0.5px dashed #6E6E6E;
                    background: rgba(240, 240, 240, 0.02);
                    box-shadow: 4px 0px 20px 0px rgba(63, 63, 63, 0.30);
                    color: rgba(240, 240, 240, 0.80);
                    font-family: Montserrat;
                    font-size: 8px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .contact__gs__list__container {
                    width: 100%;
                    display: none;
                    flex-direction: column;
                    gap: 8px;

                    &.show_container {
                        display: flex;
                    }

                    .badge {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: var(--4, 4px);
                        background: rgba(240, 240, 240, 0.05);
                        color: var(--Primary_sat, #F2A93B);
                        font-family: Montserrat;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        padding: 6px 12px;
                    };

                    .gs__details__container {
                        width: 100%;
                        position: relative;
                        display: flex;
                        padding: 6px 12px;
                        align-items: center;
                        justify-content: space-between;
                        gap: 4px;
                        border-radius: 4px;
                        border: 0.5px solid rgba(240, 240, 240, 0.20);
                        background: #0B0C0D;
                        box-shadow: 4px 0px 20px 0px rgba(63, 63, 63, 0.30);

                        &.selected {
                            border: 0.5px solid #CCF54E;
                            background: rgba(204, 245, 78, 0.10);
                            box-shadow: 4px 0px 20px 0px rgba(63, 63, 63, 0.30);
                        };

                        .gs__details__label {
                            width: 95%;
                            color: #F0F0F0;
                            font-family: Montserrat;
                            text-align: start;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        };

                        .gs__details__other__info {
                            color: rgba(240, 240, 240, 0.80);
                            font-family: Montserrat;
                            font-size: 9px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        .cancel__contact{
                            position: absolute;
                            top: -10px;
                            right: -10px;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: rgba(26, 26, 25, 0.6);
                            border-radius: 100%;
                            backdrop-filter: blur(25px);
                            cursor: pointer;
                            &:hover{
                            background: rgba(26, 26, 25, 0.8);
                            }
                        }

                        &:disabled{
                            cursor: default;
                            pointer-events: none;
                            opacity: 0.6;
                        }
                    }
                }

                .form__submit__btn__container {
                    width: 100%;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    gap: 8px;

                    .execute__btn {
                        transition: transform 0.1s ease, box-shadow 0.1s ease;
                        width: auto;
                        flex: 1;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 4px;
                        color: #F0F0F0;
                        font-family: Montserrat;
                        border-radius: var(--4, 4px);
                        background: #20563D;
                        border: 0.5px solid rgba(240, 240, 240, 0.20);
                        
                         &:active {
                            transform: scale(0.95); // Slightly press the button
                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) inset; // Inset shadow for a pressed effect
                            background-color: #0d2218; // Optional: slightly darker background color
                        }      

                        &:disabled {
                            opacity: 0.6;
                            cursor: default;
                            pointer-events: none
                        }
                    };
                    .schedule__btn__container{
                        width: 100%;
                        height: 100%;
                        display: none;    
                        position: relative;      
                        .schedule__btn {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-radius: 4px;
                            color: #F0F0F0;
                            font-family: Montserrat;
                            border-radius: var(--4, 4px);
                            background: transparent;
                            border: 0.5px solid rgba(240, 240, 240, 0.70);
                            padding: 0px 10px;
                        }
                        .show__text{
                            display: flex;
                        }
                        &.show_btn {
                            display: flex;
                        }
    
                        &.small {
                            .show__text{
                                display: none;
                            }
                            padding: 0;
                            width: 36px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        };
                        .overlay__modal {
                            height: 0px;
                            width: 0px;
                            position: absolute;
                            overflow: hidden;
                            bottom: 20px;
                            left: 20px;
                            background: #0A0A0A;
                            transition: all 0.3s ease;
                            cursor: default;
    
                            &.active {
                                bottom: 0;
                                left: 50px;
                                height: 300px;
                                width: 350px;
                                border-radius: var(--4, 4px);
                                border: 0.5px solid rgba(240, 240, 240, 0.50);
                                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                            };
    
                            .modal__container {
                                height: 100%;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                padding: 8px;
                                color: #F0F0F0;
                                font-family: Montserrat;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
    
                                .modal__header {
                                    height: 40px;
                                    width: 100%;
                                    display: flex;
                                    gap: 11px;
                                    align-items: center;
                                    border-radius: var(--4, 4px);
                                    border: 1px solid #2465B2;
    
                                    .search__icon {
                                        height: 40px;
                                        width: 40px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
    
                                        svg {
                                            stroke-width: 1px;
                                            stroke: #CCF54E;
                                        }
                                    }
    
                                    input {
                                        flex: 1;
                                        height: 100%;
                                        border: 0;
                                        outline: none;
                                        background: transparent;
    
                                        &::placeholder {
                                            color: rgba(255, 255, 255, 0.65);
                                        }
    
                                        &:focus {
                                            border: 0;
                                            outline: none;
                                        }
                                    };
                                };
    
                                .modal__body {
                                    width: 100%;
                                    flex: 1;
                                    overflow-y: auto;
                                    display: flex;
                                    flex-wrap: nowrap;
                                    flex-direction: column;
                                    gap: 4px;
                                    &::-webkit-scrollbar {
                                        display: none;
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background: transparent;
                                    };
    
                                    .contact__details__container{
                                        width: 100%;
                                        height: 50px;
                                        height: auto;
                                        display: flex;
                                        padding: 6px;
                                        justify-content: space-between;
                                        align-items: center;
                                        border-radius: 4px;
                                        gap: 4px;
                                        background: rgba(240, 240, 240, 0.06);
                                        &:hover{
                                            background: rgba(240, 240, 240, 0.1);
                                        }
                                        &.selected{
                                            background: rgba(240, 240, 240, 0.2);
                                        }
                                        .icon__container{
                                            width: 30px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            gap: 0px;
                                        }
                                        .right__contact__details,
                                        .left__contact__details{
                                            display: flex;
                                            flex-direction: column;
                                            gap: var(--4, 4px);
                                            width: 170px;
                                            span{
                                                width: 100%;
                                                color: #F0F0F0;
                                                font-family: Montserrat;
                                                font-size: 11px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                text-align: start;
                                                &:nth-child(2){
                                                    font-size: 8px;
                                                    opacity: 0.8;
                                                }
                                            }
                                        };
                                        .right__contact__details {
                                            width: 120px;
                                            span{
                                                opacity: 0.8;
                                                text-align: end;
                                            }
                                        }
                                        &:disabled{
                                            opacity: 0.6;
                                            pointer-events: none;
                                            cursor: default;
                                        }
                                    }
    
                                };
    
                                .modal__footer {
                                    width: 100%;
                                    height: 20px;
                                    display: flex;
                                    align-items: start;
    
                                    .modal__footer__btn {
                                        background: transparent;
                                        padding: 0px;
                                        height: 100%;
                                        width: auto;
                                        color: #36F097;
                                        line-height: 24px;
                                        /* 200% */
                                        letter-spacing: -0.18px;
                                        text-decoration-line: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.console__container__right__panel {
    display: flex;
    height: 100%;
    width: 50px;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(25px);
    padding: 12px 10px;
    padding-bottom: 0px;
    transition: all 0.3s ease;
    overflow: hidden;

    &.active {
        background: rgba(0, 0, 0, 0.50);
        width: calc(100vw - 380px);
        max-width: 1430px;
        overflow: visible;

        .console__container__right__panel__container {
            .right__panel__header {
                justify-content: space-between;

                .header__title {
                    display: flex
                }

                .right__panel__colapse__btn {
                    transform: rotate(180deg);
                }
            };

            .right__panel__body {
                width: 100%;
                overflow: visible
            }
        }
    };

    .console__container__right__panel__container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .right__panel__header {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 30px;
            border-bottom: 0.5px solid rgba(240, 240, 240, 0.08);
            padding-bottom: 12px;

            .header__title {
                display: none;
                color: #CCF54E;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .custom__toggle__btn__container{
                width: 24px;
                height: 16px;
                display: flex;
                align-items: center;
                border-radius: 2px;
                position: relative;
                background: rgba(240, 240, 240, 0.2);
                &::before{
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 2px;
                    background: #F0F0F0;
                    position: absolute;
                    left: 2px;
                    right: unset;
                    transition: all 0.3s ease;
                }
                &.active {
                    &::before {
                        background: rgb(204, 245, 78);
                        right: 2px;
                        left: unset;
                    }
                }
                &:disabled{
                    opacity: 0.6;
                    pointer-events: none;
                    cursor: default;
                }
            }

            .right__panel__close__btn,
            .right__panel__pin__btn,
            .right__panel__colapse__btn {
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                padding: 0px;
                width: 30px;
                height: 30px;
                transform: rotate(0deg);
                transition: all 0.3s ease;
            };

            .right__panel__pin__btn.pinned {
                border-radius: var(--4, 4px);
                background: rgba(204, 245, 78, 0.10);
                svg{
                    transform: rotate(-45deg);
                    stroke: #CCF54E;
                    stroke-width: 0.5px;
                    transition: all 0.3s ease;
                }
            }
        };

        .right__panel__body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 0;
            height: 100%;
            overflow: hidden;

            .right__panel__body__header {
                display: flex;
                align-items: center;
                justify-content: start;
                width: auto;
                height: 50px;

                .header__badge__btn {
                    display: flex;
                    padding: var(--6, 6px) 12px;
                    gap: 10px;
                    border-radius: var(--4, 4px);
                    color: #F0F0F0;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    background: transparent;
                    opacity: 0.8;

                    &.active {
                        color: #CCF54E;
                        background: rgba(240, 240, 240, 0.05);
                    }
                }
            };

            .right__panel__body__content__container {
                height: calc(100vh - 180px);
                width: 100%;
                display: flex;
                flex-direction: column;
                overflow: hidden;

                /* gap: 6px; */
                .content__container__table__header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 40px;
                    padding: 0px 12px;
                    border-radius: var(--4, 4px);
                    background: #191A1A;
                    color: #F0F0F0;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    gap: 12px;

                    .table__header__btn {
                        padding: 0;
                        height: auto;
                        width: auto;
                        background: transparent;

                        &:disabled {
                            cursor: default;
                            pointer-events: none;
                            opacity: 0.5;
                        }
                    }
                };

                .content__container__table__body {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 8px;
                    padding: 10px 0px;
                    flex: 1;
                    height: 100%;
                    overflow-y: visible;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: transparent;
                    };

                    .table__body__row {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        position: relative;
                        transition: all 0.3s ease;
                        border-radius: var(--4, 4px);

                        &:hover {
                            background: rgba(240, 240, 240, 0.10);
                        }

                        .table__body__row__item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            padding: 6px 12px;
                            background: transparent;
                            color: #AEAEAE;
                            font-size: 12px;

                            &.truncate__row {
                                height: 2px;
                                width: 2px;
                                border-radius: 100%;
                                background: #AEAEAE;
                                overflow: hidden;
                                border: none;
                                padding: 0;
                                pointer-events: none;
                                cursor: default;

                                &:hover {
                                    background: transparent;
                                }
                            }

                            &.request {
                                border-left: 2px solid #CCF54E;

                                &.selected {
                                    background: rgba(204, 245, 78, 0.1);
                                }
                            };

                            &.response {
                                border-left: 2px solid #36F097;

                                &.selected {
                                    background: rgba(54, 240, 151, 0.1)
                                }
                            }
                            &.selected {
                                background: rgba(54, 240, 151, 0.1)
                            }
                            .truncate__text {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                &.uppercase {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                };

                .container__table__body__resize__handler {
                    height: 2px;
                    width: 100%;
                    background: rgba(204, 245, 78, 0.10);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    cursor: row-resize;
                    bottom: 0;
                    left: 0;

                    &.active,
                    &:hover {
                        height: 3px;
                        background: rgb(142, 142, 142);
                    }
                }

                .output__modal__left__resize__handler {
                    height: 100%;
                    width: 2px;
                    background: rgba(204, 245, 78, 0.10);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    cursor: col-resize;
                    top: 0;
                    right: 0;

                    &.active,
                    &:hover {
                        width: 3px;
                        background: rgb(142, 142, 142);
                    }
                }

                .output__modal {
                    width: 100%;
                    display: flex;
                    transition: all 0.3s ease;
                    flex: 1;
                    background: rgba(0, 0, 0, 0.50);
                    backdrop-filter: blur(25px);
                    overflow-y: auto;

                    .output__modal__left__section {
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        width: 50%;
                        height: 100%;
                        overflow-y: auto;
                        padding: 10px 20px;
                        gap: 8px;
                        border-right: 0.5px solid rgba(240, 240, 240, 0.20);
                        color: #abaaaa;
                        background-color: transparent;
                    };

                    .output__modal__right__section {
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                        overflow-y: auto;
                        padding: 10px 20px;
                        gap: 8px;
                        color: #abaaaa;
                        word-wrap: break-word;
                        /* Ensure long strings wrap within the container */
                        color: #abaaaa;
                        background-color: transparent;

                    }

                }
            }
        };
    }



}

.pretty-button {
    background: linear-gradient(to right, #6a11cb, #2575fc);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pretty-button.loading {
    background: linear-gradient(to right, #f2994a, #f2c94c);
}

.pretty-button:disabled {
    cursor: not-allowed;
}

.skeleton-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.skeleton-wrapper .skeleton-item {
    height: 50px;
    border-radius: 5px;
}

.button__load__more {
    position: absolute;
    top: -36px;
    right: 10px;
    border-radius: 5px;
    opacity: 0.7;
    cursor: pointer;  // Added cursor style to mimic button hover effect
    padding: 4px 8px;  // Added padding for better appearance
    color: #CCF54E;
    background: rgba(204, 245, 78, 0.1);
    align-items: center;
    justify-content: center;
    z-index: 999;
    .button__load__more__tooltip {
        width: 140px;
        display: none;
        color: #d8d8d8;
        background-color: #121213;
        padding: 6px 12px;
        font-size: 12px;
        border-radius: 4px;
        border: 0.5px solid rgba(240, 240, 240, 0.20);
        filter: blur(15%);
        position: absolute;
        top: 40px;
        right: 0px;
    }
    &:hover {
        .button__load__more__tooltip {
            display: flex;
        }
    }
}
.spin__icon {
  animation: spin_loader 1000ms linear infinite;
  -webkit-animation: spin_loader 1000ms linear infinite;
}

@keyframes spin_loader {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.custom-checkbox input {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #EEEEEE !important; /* Set the desired border color */
    background-color: transparent;
    border-radius: 3px !important;
    cursor: pointer;
    width: 15px !important;
    height: 15px !important
}

.custom-checkbox input:checked {
    background-color: #F0F0F0; /* Optional: add a background color for checked state */
    border-color: #F0F0F0 ; /* Keep the same border when checked */
}

.bg-typeBeacon{
    background-color:rgba(122, 179, 255, 0.10);
}

.bg-typeHealthMetric{
    background-color:rgba(122, 179, 255, 0.10);
}

.bg-typeTM{
    background-color:rgba(122, 179, 255, 0.10);
}
.bg-invalidTM{
    background-color:rgba(201, 24, 24, 0.10);
}
