.iconText{
    color: #F0F0F0;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-left: 4px;
}
.iconTextSmall{
    color: #F0F0F0;
font-family: Montserrat;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.iconHeight{
color: #F0F0F0;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}