.modalFooter-edit {
  flex: 1 1 auto;
  height: 6vh;
  width: 100%;
  padding: 12px;
  overflow: hidden;
  border-style: solid;
  border-width: 0px;
  border-top-width: 0.1px;
  border-image: linear-gradient(135deg, rgba(240, 240, 240, 0.20), #000) 1;
  border-bottom: 0.5px solid rgba(240, 240, 240, 0.20);
}

.modalFooterclose-edit,
.modalFooterapply-edit {
  padding: 8px 24px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modalFooterapplySatCreation {
  padding: 4px 10px 8px 14px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 12px;
  border-radius: 4px;
  background: #20563d;
  transition: 300ms;
}

.modalFooterclose-edit {
  position: absolute;
  left: 12px;
  background: transparent;
  border-radius: 4px;
  border: 0.5px solid rgba(240, 240, 240, 0.5);
  transition: 300ms;
}

.modalFooterclose-edit:hover {
  background: rgba(240, 240, 240, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(160, 160, 160, 0.2);
}

.modalFooterapply-edit {
  position: absolute;
  right: 12px;
  transition: 300ms;
  border-radius: 4px;
  background: rgba(240, 240, 240, 0.10);
}

.modalFooterapply-update {
  background: rgba(240, 240, 240, 0.10);
  cursor: not-allowed;
  pointer-events: none;
}

.modalFooterapply-update.enable-btn {
  background: #45570D;
  cursor: pointer;
  pointer-events: all;
}

.modalFooterapply-create {
  background: #45570D;
  cursor: pointer;
  pointer-events: all;
}

.disable_analysis_button-edit {
  opacity: 0.4;
}

.enable_analysis_button-edit {
  background: #45570D;
}

.modal-footer__close-additional-object {
  border-radius: 4px;
  background: transparent;
  border: 0.5px solid #81A60E;
  color: #81A60E;
  transition: 300ms;
  font-size: 12px;
  padding: 8px 24px;
}

.modal-footer__next-additional-object {
  border-radius: 4px;
  background: #58700D;
  color: #F0F0F0;
  transition: 300ms;
  font-size: 12px;
  padding: 8px 24px;
}