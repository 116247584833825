.scenario__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    .scenario__header{
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 100;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }
}
