.confirmation__dialog__container {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(9, 9, 9, 0.50);
    backdrop-filter: blur(15px);

    &.open {
        display: flex;
        animation: dialogOpen 0.3s ease forwards;
    }

    /* Add closing animation */
    &.close {
        animation: dialogClose 0.3s ease forwards;
        pointer-events: none; /* Prevent interactions during close animation */
    }

    .confirmation__dialog__center__container {
        display: flex;
        width: 400px;
        height: 200px;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        border-radius: 0px 16px;
        background: rgba(17, 17, 17, 0.50);
        &.discard{
            border: 0.5px solid #F2A93B;
        }

        &.delete{
            border: 0.5px solid #F33;
            .confirmation__dialog__footer .save_closee__button{
                background: #F33;
            }
        }
        &.update{
            border: 0.5px solid #CCF54E;
            .confirmation__dialog__footer .save_closee__button{
                background: #CCF54E;
            }
        }

        .confirmation__dialog__header{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            gap: 12px;
            .confirmation__dialog__title {
                color: #D9D9D9;
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 100% */
                text-align: start;
            }
            .close__button{
                cursor: pointer;
                display: flex;
                background-color: transparent !important;
            }
        }
        .confirmation__dialog__body{
            color: #D9D9D9;
            font-family: Montserrat;
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
        }
        .confirmation__dialog__footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .discard__button,
            .save_closee__button,
            .cancel__button{
                display: flex;
                height: 35px;
                padding: 9px 12px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 4px;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .cancel__button{
                color: #F0F0F0;
                border: 1px solid rgba(217, 217, 217, 0.20);
            }
            .save_closee__button{
                color: rgba(0, 0, 0, 1);
                background: #F2A93B;
            }
        }
    }
}

/* Keyframes for opening and closing animations */
@keyframes dialogOpen {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes dialogClose {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.9);
    }
}