/* Button to open modal */
.upload-schedule-btn {
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* Modal overlay */
.upload-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.20) !important;
    backdrop-filter: blur(20px) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal container */
.upload-modal-container {
    border: 1px solid dimgrey;
    background-color: #000;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

/* Modal header */
.upload-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
}

.upload-modal-header h2 {
    font-size: 18px;
    margin: 0;
}

.upload-modal-close {
    background: none !important;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
}

/* Modal content zones */
.upload-modal-content {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.upload-drop-zone,
.upload-device-zone {
    flex: 1;
    height: 120px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.upload-device-zone {
    margin-right: 0;
    margin-top: 0;
    /* Remove margin for last zone */
}

.upload-drop-zone:hover,
.upload-device-zone:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Modal footer */
.upload-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-cancel-btn,
.upload-import-btn {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.upload-cancel-btn {
    background: none !important;
    border-radius: 4px !important;
    border: 0.5px solid rgba(240, 240, 240, 0.50) !important;
    color: rgba(240, 240, 240, 0.50);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.upload-import-btn {
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px !important;
    opacity: 0.6 !important;
    background: rgba(240, 240, 240, 0.40) !important;
}

.upload-import-btn:disabled {
    background-color: #222 !important;
    cursor: not-allowed;
}

.import_schedule_button i {
    color: rgba(240, 240, 240, 0.7) !important;
}

.import_schedule_button:hover i {
    color: #ccf54e !important;
}