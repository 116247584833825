.create_mission_container{
    width: 1200px;
    align-items: flex-start;
    margin: 150px;
}

.mission_heading{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 83.333% */
}

.separator {
    border-top: 0.5px solid #6E6E6E;
    margin-top: 24px;
    margin-bottom: 24px;
}

.header_font{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.field_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    flex: 1 0 0;
}

.input_field{
    display: flex;
    height: 40px;
    padding: 8px;
    align-items: center;
    gap: 80px;
    align-self: stretch;
    border-radius: 4px;
    border: 0.5px solid rgba(240, 240, 240, 0.30);
    background: rgba(10, 10, 10, 0.65);
}

.horizontal_container{
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.mission_obj_items{
    display: flex;
    flex-direction: row;
    gap: 24px
}

.mission_obj_item{
    display: flex;
    cursor: pointer;
    height: 40px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: rgba(240, 240, 240, 0.65);
    border: 0.5px solid rgba(240, 240, 240, 0.30);
    background: rgba(10, 10, 10, 0.65);
}

.mo_font{
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.selected_mo{
    border: 0.5px solid rgba(204, 245, 78, 0.80);
    color: #CCF54E;
}

.submission_buttons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    margin: 24px;
}

.submit_font{
    color: #EEE;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cancle_button{
    border: 1px solid #F00;
}

.submit_button{
    background: #20563D;
}