.deploy__model{
    display: none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(15px);
    &.active{
        display: flex;
    }

    .deploy__model__center{
        display: flex;
        width: 520px;
        max-height: 400px;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 0px 16px;
        border: 0.5px solid rgba(240, 240, 240, 0.65);
        background: rgba(17, 17, 17, 0.50);
        backdrop-filter: blur(12.300000190734863px);
        transition: all 0.3s ease-in-out;
        &.large{
            width: 600px;
            min-height: 450px;
        }
        .deploy__model__center__body{
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 12px;
            width: 100%;
            padding: 24px 0px;
            border-top: 1px solid transparent; /* Set a transparent solid border initially */
            border-bottom: 1px solid transparent; /* Set a transparent solid border initially */
            border-image: linear-gradient(to right, #B7B7B7, transparent); /* Adjust the gradient direction and colors as needed */
            border-image-slice: 1; /* Make the entire border a gradient */
        }
        .model__center__body__container{
            display: flex;
            gap: 6px;
            flex-direction: column;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.05);
            height: 100%;
            width: 100%;
            padding: 12px;
        }

        .deploy__model__center__header,
        .deploy__model__center__footer{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
        .header__close__button{
            display: flex;
            padding: 0px;
            height: auto;
            width: auto;
            background: transparent;
            border-radius: 100%;    
        }
        .footer__next__button,
        .footer__close__button{
            display: flex;
            height: 35px;
            width: auto;
            padding: 0px 24px;
            background: transparent;   
            align-items: center;
            justify-content: center;     
            border-radius: 4px;  
            border: 1px solid rgba(240, 240, 240, 0.20);  
        }
        .footer__next__button{
            background: #45570D;
        }
    }
}

.deploy__type__items{
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    gap: 4px;
    align-self: stretch;
    border-radius: 0px 8px;
    border: 0.5px solid rgba(142, 142, 142, 0.47);
    background: rgba(17, 17, 17, 0.50);
    backdrop-filter: blur(12.30px);
    &.active{     
        border: 0.5px solid rgba(203, 245, 78, 0.47);
        background: rgba(203, 245, 78, 0.1);
    }
    
}

.navigation__header{
    display: flex;
    width: 100%;
    padding: 6px 0px;
    align-items: center;
    justify-content: space-between;
    .navigation__header__item{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 1px 0px 12px 0px;
        gap: 12px;
        border-bottom: 1px solid rgba(240, 240, 240, 0.20);
        background: transparent;
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: all 0.3s ease-in-out;
        &:disabled{
            opacity: 0.6;
            cursor: default;
            pointer-events: none;
        }
        &:not(:disabled):hover{
            color: #CCF54E;
            svg{
                stroke: #CCF54E;
            };
            svg path{
                stroke: #CCF54E;
            }
        }
        &.active{
            border-bottom: 1px solid #CCF54E;
            color: #CCF54E;
            svg{
                stroke: #CCF54E;
            };
            svg path{
                stroke: #CCF54E;
            }
        }
    }
}

.custom__text__input {
    display: flex;
    padding: 6px 12px;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px !important;
    border: 0.5px solid rgba(240, 240, 240, 0.30) !important;
    background: rgba(10, 10, 10, 0.65) !important;
    &:disabled{
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
}

.info__badge{
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(36, 101, 178, 0.10); 
    opacity: 0.8; 
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal; 
    .heighlit_text{
        font-weight: 700;
    }
}

.bus__form__container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    align-content: start;
    gap: 12px;

    .bus__form__item{
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        border-radius: 0px 8px;
        border: 0.5px solid rgba(240, 240, 240, 0.40);
        background: rgba(9, 9, 9, 0.29);
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .check__button__container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;
        .button_text{
            color: #F0F0F0;
            text-align: right;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.8;
        }
        .toggle__button__container{
            display: flex;
            position: relative;
            width: auto;
            height: auto;
            align-items: center;
            background: transparent;
            overflow: hidden;
            border-radius: 2px;
            padding: 0px;
            .toggle__button{
                width: 24px;
                height: 16px;
                background: #F0F0F0;
                opacity: 0.12;
            }
            .toogle__button__check{
                opacity: 0.4;
                background: #F0F0F0;
                position: absolute;
                left: 0;
                width: 12px;
                height: 14px;
                border-radius: 2px;
                transition: all 0.3s ease-in-out;
            }
        }

        &.check{
            .button_text{
                color: #CCF54E;
            }
            .toggle__button__container .toogle__button__check{
                left: 12px;
                background: #CCF54E;
                opacity: 0.8;
            }
        }
    }
}