.preview-schedule {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    right: 0px;
    top: 85px;
    width: 700px;
    height: 650px;
    border-radius: 8px;
    border: 1px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(50px);
    z-index: 100;
}

.preview-schedule-title {
    display: flex;
    font-size: 16px;
}

.preview-schedule-title-text {
    margin: 0;
    padding-left: 4px;
}

.preview-schedule-orders-summary {
    display: flex;
}

.preview-schedule-close-action img {
    height: 14px;
    width: 14px;
}

.preview-schedule-orders-text {
    font-size: 12px;
    font-style: italic;
    margin-right: 10px;
}

.preview-schedule-header {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-size: 12px;
    border-bottom: 0.5px solid rgba(240, 240, 240, 0.60);
    backdrop-filter: blur(50px);
    height: 40px;
}

.schedule-table-section {
    font-size: 12px;
    height: auto;
}

.schedule-table {
    width: 100%;
}

.schedule-table-header {
    background: rgba(240, 240, 240, 0.10);
}

.schedule-table-header th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.schedule-table-content {
    display: block;
    max-height: 520px;
    overflow-y: auto;
}

.schedule-table-row {
    display: flex;
    padding: 8px 13px;
    align-items: center;
    height: 30px;
    align-self: stretch;
    justify-content: space-between;
}

.schedule-table-column-width:nth-child(1) {
    width: 35%;
}

.schedule-table-column-width:nth-child(2) {
    width: 25%;
}

.schedule-table-column-width:nth-child(3) {
    width: 20%;
}

.schedule-table-column-width:nth-child(4) {
    width: 20%;
}

.time-column {
    color: rgba(240, 240, 240, 0.60);
}

.schedule-table-column {
    color: #F0F0F0;
}

.assignment-icon {
    margin-right: 6px;
}

.preview-schedule-actions {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 8px;
}

.preview-schedule-button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

.preview-schedule-actions-group {
    display: flex;
    gap: 10px;
}

.ps-btn-light {
    border-radius: 4px;
    background: #45570D;
}

.ps-btn-dark {
    color: rgba(240, 240, 240, 0.50);
    border-radius: 4px;
    border: 0.5px solid rgba(240, 240, 240, 0.50);
    background: transparent;
}

.loading-schedule-msg {
    margin: 10px;
}