.settings__orbit__layer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40px;
    position: absolute;
    right: 20px;
    top: -55px;
    overflow: hidden;
    height: 40px;
    color: #E3E3E3;
    z-index: 999;
}
.setting__orbit__layer__container{
    display: flex;
    flex-direction: column;
}
.settings__orbit__layer .setting__orbit__button__handler,
.settings__orbit__layer .setting__orbit__layer__container .setting__orbit__button__style{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 40px;
    width: 40px;
    padding: 7px;
    background: #0000008b;
    backdrop-filter: blur(10px);
    display: flex;
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    align-items: center;
    justify-content: center;
    transform: translateY(0px);
    transition: all 0.3s ease-in-out;
    z-index: 99;
}

.settings__orbit__layer .setting__orbit__button__handler:hover,
.settings__orbit__layer .setting__orbit__layer__container .setting__orbit__button__style:hover{
    border: 0.5px solid rgba(204, 245, 78, 0.50);
    background: #1C2113;
}
.settings__orbit__layer .setting__orbit__button__handler.active:hover
.settings__orbit__layer .setting__orbit__layer__container .setting__orbit__button__style.active:hover,
.settings__orbit__layer .setting__orbit__layer__container .setting__orbit__button__style.active{
    border: 0.5px solid rgba(204, 245, 78, 0.50);
    background: #1C2113;
}
.settings__orbit__layer.active { 
    width: 350px;
    height: 310px;
    top: -325px;
    .setting__orbit__layer__container > :nth-child(1){
        transform: translateY(-65px)
    };
    .setting__orbit__layer__container > :nth-child(2){
        transform: translateY(-115px)
    };
    .setting__orbit__layer__container > :nth-child(3){
        transform: translateY(-165px)
    };
    .setting__orbit__layer__container > :nth-child(4){
        transform: translateY(-215px)
    };
    .setting__orbit__layer__container > :nth-child(5){
        transform: translateY(-265px)
    };
    .setting__orbit__button__handler{
        border: 0.5px solid rgba(204, 245, 78, 0.50);
        background: #1C2113;
    }
}
.setting__orbit__details__layer{
    position: absolute;
    width: 0px;
    height: 0px;
    display: flex;
    bottom: 15px;
    right: 20px;
    border-radius: 8px;
    flex-direction: column;
    gap: 14px;
    transition: 0.3s ease-in-out;
    z-index: 99;
    overflow: hidden;
    border: 1px solid #8c8282;
    background: rgba(0, 0, 0, 1);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.19);
    backdrop-filter: blur(5px);
}
.setting__orbit__details__layer.active{
    bottom: 0;
    right: 50px;
    padding: 15px 10px;
    height: 300px;
    width: 300px;
    z-index: 99;
}