.GeoFeatureCreationHeader {
    display: flex;
    padding: 8pt 4pt 8pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.02);
}

.svgContainerGeo {
    height: 16pt;
    width: 16pt;
}

.headerText {
    color: rgba(240, 240, 240, 0.69);
    font-family: Montserrat;
    font-size: 11pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.165pt;
}

.headerSubText {
    color: #8C8C8C;
    font-family: Montserrat;
    font-size: 10pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.15pt;
    opacity: 0.5;
}

.creationState {
    display: flex;
    align-items: center;
    gap: 4pt;
}

.addIcon {
    cursor: pointer;
}

.GeoFeatureCreationBody {
    display: flex;
    padding: 10px;
    justify-content: center;
    vertical-align: middle;
    flex: 1 0 0;
    align-self: stretch;
    overflow-y: auto;
}

.bodyText {
    color: #FFF;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.18pt;
    opacity: 0.3;
}

.create_geo_feature_alignment {
    margin-top: 25%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.geoFeatureOptions {
    display: flex;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 2;

    .feature-option {
        display: flex;
        height: 40px;
        padding: 12px;
        margin: 0 12px;
        align-items: center;
        gap: 4px;
        border-radius: var(--small, 4px);
        border: 0.411px solid rgba(240, 240, 240, 0.20);
        background: rgba(15, 15, 15, 0.69);
        box-shadow: 0px 0px 16.425px 0px rgba(52, 52, 52, 0.30);
        backdrop-filter: blur(102.6570053100586px);
        min-width: 70px;
        justify-content: center;

        .draw-icon {
            padding: 4px 6px;
            background: none;

            svg {
                height: 20px;
                width: 20px;
            }
        }
        .draw-icon.active-action {
            background: rgb(240, 245, 78, 0.1);
        }
    }
}

.aoiList {
    z-index: 99;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    .aoi-item {
        display: flex;
        padding: 4px 0px 4px 8px;
        flex-direction: row;
        justify-content: space-between;
        
        .aoi-icon {
            margin-right: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .aoi-item:hover{
        border-radius: 4px;
        background: rgb(240, 245, 78, 0.1);
        cursor: pointer;
    }

    .aoi-item:hover .aoi-icon {
        margin-bottom: 3px;
    }
    
    .right_heder_icon_aoi{
        display: none;
        padding-top: 4px;
        padding-right: 12px;
        &.show{
            display: flex;
        }
    }
}

.main_geo_feature{
    display: flex;
    flex-direction: column;
    height: 100%;
}

.geofense_aoi_alignment_state{
    color: #f0f0f0;
    display: flex;
    flex-direction: row;
    gap: 4pt;
}
.gefofense__aoi__name{
    width: 200px;
    color: #f0f0f0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}

