.simulation-speed-indicator_container {
    display: inline-flex;
    align-items: center;
    height: 30px;
    padding: 0 6px;
    gap: 4px;
    border-radius: 4px;
    border: 0.5px solid rgba(204, 245, 78, 0.40);
    background: rgba(204, 245, 78, 0.10);
    flex-shrink: 0;
    margin: auto;
}

.simulation-speed-indicator__text {
    color: rgba(204, 245, 78, 0.94);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}