.input_modal_with_unit{
    display: flex;
    flex-direction: row;
    border-radius: 4px !important;
    border: 0.5px solid rgba(240, 240, 240, 0.30) !important;
    overflow: hidden;
}

.input_modal{
    border-radius: 0px !important;
    background: rgba(10, 10, 10, 0.65) !important;
    display: flex;
    width: 10vh;
    padding: 4px 8px; 
    justify-content: flex-end;
    align-items: center;
    border: none;
    border-right: 0.5px solid rgba(240, 240, 240, 0.30) !important;;
    gap: 80px;
}
.input_modal:disabled{
    cursor: default;
    pointer-events: none;
    opacity: 0.6;
}

.input_modal.required{
    width: 10vh;
}

.input_modal.not_required{
    width: 15vh;
}

.unit_modal{
    display: flex;
    padding: 4px 8px;
    width: 5vh;
    align-items: center;
    gap: 80px;
    border-radius: var(--none, 0px) 4px 4px var(--none, 0px) !important;
    background: rgba(10, 10, 10, 0.65) !important;
}
.unit_modal:disabled{
    cursor: default;
}

.transparent-mode .form-control{
    border-color: 0.5px rgba(240, 240, 240, 0.30) !important;
}

.rename_section{
    display: flex;
    flex-direction: row;
    gap: 10pt;
    align-items: center;
    align-content: center;
}
.svg_icon_container__btn{
    background: transparent;
    padding: 0px;
}
.svg_icon_container__btn:disabled{
    background: transparent;
    cursor: auto;
    opacity: 0.6;
}