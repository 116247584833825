.scenario__visualization__container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .visualization__section{
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        position: relative;
    }
    .timeline__section{
        display: none;
        width: 100%;
        height: 200px;
        overflow: hidden;
    }
    &.show__timeline{
        .visualization__section{
            height: calc(100% - 200px);
        }
        .timeline__section{
            display: block;
        }
    }
}

.timeline_skeleton{
  width: 100%;
  display: flex;
  border-radius: 8px;
  opacity: 0.43;
  background: linear-gradient(90deg, rgba(240, 240, 240, 0.20) 0%, rgba(240, 240, 240, 0.06) 51.71%);
  animation: blink 2s infinite;
  -webkit-animation: blink 2s infinite;
}
@keyframes blink {
  0% {
    opacity: 0.43;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.43;
  }
}